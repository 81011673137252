<template>
  <div>
    <!-- The modal -->
    <b-modal
      :id="id"
      ref="modal"
      title="Edytuj Insurera"
      ok-title="Zaktualizuj"
      cancel-title="Anuluj"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      @cancel="resetForm"
      @ok="handleModalOk"
      @shown="fillModal"
    >
      <b-form novalidate>
        <b-form-group
          label="name"
          label-for="name"
          invalid-feedback="To pole jest wymagane"
          :state="null"
        >
          <b-form-input id="input" v-model="form.name"></b-form-input>
        </b-form-group>

        <b-form-group
          label="full_name"
          label-for="full_name"
          invalid-feedback="To pole jest wymagane"
          :state="null"
        >
          <b-form-input id="input" v-model="form.full_name"></b-form-input>
        </b-form-group>

        <b-form-group
          label="street_name"
          label-for="street_name"
          invalid-feedback="To pole jest wymagane"
          :state="null"
        >
          <b-form-input id="input" v-model="form.street_name"></b-form-input>
        </b-form-group>

        <b-form-group
          label="street_prefix"
          label-for="street_prefix"
          invalid-feedback="To pole jest wymagane"
          :state="null"
        >
          <b-form-input id="input" v-model="form.street_prefix"></b-form-input>
        </b-form-group>

        <b-form-group
          label="building_number"
          label-for="building_number"
          invalid-feedback="To pole jest wymagane"
          :state="null"
        >
          <b-form-input
            id="input"
            v-model="form.building_number"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="apartment_number"
          label-for="apartment_number"
          invalid-feedback="To pole jest wymagane"
          :state="null"
        >
          <b-form-input
            id="input"
            v-model="form.apartment_number"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label="postcode"
          label-for="postcode"
          invalid-feedback="To pole jest wymagane"
          :state="null"
        >
          <b-form-input id="input" v-model="form.postcode"></b-form-input>
        </b-form-group>

        <b-form-group
          label="city"
          label-for="city"
          invalid-feedback="To pole jest wymagane"
          :state="null"
        >
          <b-form-input id="input" v-model="form.city"></b-form-input>
        </b-form-group>

        <b-form-group
          label="province"
          label-for="province"
          invalid-feedback="To pole jest wymagane"
          :state="null"
        >
          <b-form-input id="input" v-model="form.province"></b-form-input>
        </b-form-group>

        <b-form-group
          label="country"
          label-for="country"
          invalid-feedback="To pole jest wymagane"
          :state="null"
        >
          <b-form-input id="input" v-model="form.country"></b-form-input>
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { INSURER_EDIT } from "@/core/services/store/insurer.module";
import { baseURL } from "@/core/services/api.service.js";
import {mapGetters} from "vuex";
import _ from "lodash";

export default {
  name: "ModalInsurerEdit",
  props: ["id", "uuid"],
  data() {
    return {
      insurer: null,
      formState: null,
      form: {
        name: "",
        full_name: "",
        street_name: "",
        street_prefix: "",
        building_number: "",
        apartment_number: "",
        postcode: "",
        city: "",
        province: "",
        country: ""
      }
    };
  },
  computed: {
    ...mapGetters(["insurers"]),
    getBaseURL() {
      return baseURL;
    }
  },
  mounted() {},
  methods: {
    resetForm() {
      this.form.name = "";
      this.form.full_name = "";
      this.form.street_name = "";
      this.form.street_prefix = "";
      this.form.building_number = "";
      this.form.apartment_number = "";
      this.form.postcode = "";
      this.form.city = "";
      this.form.province = "";
      this.form.country = "";
    },
    validateForm() {
      this.formState = true;
    },
    handleModalOk(event) {
      event.preventDefault();
      this.handleSubmit();
    },
    handleSubmit() {
      this.validateForm();
      if (this.formState) {
        let formToSend = this.form;
        this.$store.dispatch(INSURER_EDIT, {
          uuid: this.uuid,
          ...formToSend
        });
        // Hide the modal manually
        this.$nextTick(() => {
          this.$bvModal.hide(this.id);
        });
      }
    },
    fillModal() {
      const insurer = _.find(this.insurers, o => {
        return o.uuid === this.uuid;
      });
      if (insurer !== undefined) {
        this.insurer = insurer;
        this.form.name = this.insurer.name;
        this.form.full_name = this.insurer.full_name;
        this.form.street_name = this.insurer.street_name;
        this.form.street_prefix = this.insurer.street_prefix;
        this.form.building_number = this.insurer.building_number;
        this.form.apartment_number = this.insurer.apartment_number;
        this.form.postcode = this.insurer.postcode;
        this.form.city = this.insurer.city;
        this.form.province = this.insurer.province;
        this.form.country = this.insurer.country;
      }
    }
  }
};
</script>
